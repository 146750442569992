(function () {
  'use strict';

  let angular = window.angular;
  let $ = window.jQuery;

  DetectionObjectsCtrl.$inject = [
    '$element',
    'AppUtils',
    'ColorUtils',
    'SensorService',
    'ObjectRecognitionService',
    'ObjectRecognitionUtilsService',
    '$translate',
    'PALETTE',
  ];

  angular.module('app').component('detectionObjects', {
    template:'<div class="draw-area raphael-draw" tabindex="0" style="position: relative; margin: auto" ng-style="{width: ctrl.width}"><div class="raphael-paper-objects"></div></div>',
    controller: DetectionObjectsCtrl,
    controllerAs: 'ctrl',
    bindings: {
      sensorType: '<',
      zone: '<',
      objects: '<',
      ratio: '<',
      width: '<',
      height: '<',
      boxMode: '<',
      poses: '<?',
      pairs: '<?',
      copyObjects: '<?',
    },
  });

  function DetectionObjectsCtrl(
    $element,
    utils,
    ColorUtils,
    SensorService,
    ObjectRecognitionService,
    ObjectRecognitionUtilsService,
    $translate,
    PALETTE
  ) {
    let vm = this;
    let firstChange = true;
    let paper;
    let drawArea;

    const linesColors = {
      person: { bg: '#b245f1', text: '#000' },
      car: { bg: '#49b9da', text: '#000' },
      default: { bg: '#fcfa1e', text: '#000' },
    };

    const segmentationColors = [
      '#7f6000',
      '#3d85c6',
      '#cc0000',
      '#274e13',
      '#1c4587',
      '#d9d2e9',
      '#8e7cc3',
      '#20124d',
      '#9900ff',
      '#00ff00',
      '#0c343d',
      '#ff9900',
      '#3c78d8',
      '#666666',
      '#660000',
      '#ffd966',
      '#45818e',
      '#674ea7',
      '#d0e0e3',
      '#f4cccc',
      '#6d9eeb',
      '#9fc5e8',
      '#073763',
      '#c9daf8',
      '#76a5af',
      '#ff0000',
      '#6aa84f',
      '#93c47d',
      '#783f04',
      '#434343',
      '#f6b26b',
      '#e06666',
      '#b6d7a8',
      '#4a86e8',
      '#a4c2f4',
      '#ea9999',
      '#d9ead3',
      '#e69138',
      '#a2c4c9',
      '#cfe2f3',
      '#6fa8dc',
      '#f9cb9c',
      '#fff2cc',
      '#fce5cd',
      '#00ffff',
      '#ffff00',
      '#b4a7d6',
      '#f1c232',
      '#cccccc',
      '#d9d9d9',
    ];

    vm.$onInit = function () {
      vm.containerEl = $element.find('.draw-area');
      vm.ratio = utils.isValidNumber(vm.ratio) ? vm.ratio : 1;

      vm.copyObjects = vm.copyObjects !== false;
      vm.objects = vm.objects || [];
      vm.objects = vm.copyObjects ? angular.copy(vm.objects) : vm.objects;

      vm.poses = vm.poses || [];
      vm.poses = vm.copyObjects ? angular.copy(vm.poses) : vm.poses;

      vm.pairs = vm.pairs || [];
      vm.pairs = vm.copyObjects ? angular.copy(vm.pairs) : vm.pairs;

      vm.circleRadius = vm.width < 400 ? 1 : 3;
      vm.stroke = vm.width < 400 ? 1 : 2;

      paper = initDrawing();
    };

    vm.$onDestroy = function () {
      if (paper) {
        paper.clear();
      }
    };

    vm.$onChanges = function (changes) {
      if (firstChange) {
        firstChange = false;
        return;
      }

      if (paper) {
        paper.clear();
      }
      if (vm.paperElement) {
        vm.paperElement.empty();
      }

      vm.$onInit();
    };

    function initDrawing() {
      const paperEl = (vm.paperElement = $element.find('.raphael-paper-objects'));
      const size = {
        width: vm.width,
        height: vm.height,
      };

      const paper = window.Raphael(paperEl[0], size.width, size.height);

      drawArea = paper.rect(0, 0, size.width, size.height);
      drawArea.attr('fill', 'transparent');
      drawArea.attr('stroke', 'none');
      paperEl.find('svg').css({ marginLeft: (paperEl.width - size.width) / 2 });

      const _classes = SensorService.getDetectorClasses();
      const _poses = ObjectRecognitionService.getPoses();
      const _posePoints = ObjectRecognitionService.getPosePoints();
      const _bodyPairs = ObjectRecognitionService.getPoseLines();

      const allowedClasses = _classes.map((current) => current.value);
      const _classesMap = utils.arrayToObject(_classes, 'value');
      const _posesMap = utils.arrayToObject(_poses, 'value');
      const _posePointsMap = utils.arrayToObject(_posePoints, 'value');

      const withPairs = {};
      vm.pairs.forEach((current) => {
        withPairs[current[0]] = true;
        withPairs[current[1]] = true;
      });

      const frames = [];
      let currentColor = 0;

      vm.objects.forEach((current, index) => {
        if (current.class && allowedClasses.indexOf(current.class) === -1) {
          return;
        }

        if (vm.sensorType === 'StoppedLicensePlate' && !current.stopped) {
          return;
        }

        const centroid = SensorService.getDetectionCentroid(current, vm.sensorType);
        if (vm.zone) {
          let filtered = true;
          if (current.filteredBy) {
            filtered =
              current.filteredBy.BLACKLIST ||
              Object.values(current.filteredBy || {}).reduce(
                (filtered, value) => filtered && value,
                true
              );
          } else {
            filtered = ObjectRecognitionUtilsService.isInsideZone(centroid, vm.zone);
          }
          if (!filtered) {
            current.hidden = true;
            return;
          }
        }

        const frame = angular.copy(current.frame);
        frame._index = index;
        frames.push(frame);

        let color = linesColors[current.class] || linesColors.default;
        if (vm.pairs.length) {
          color = {
            bg: withPairs[index] ? ColorUtils.getColor('danger') : ColorUtils.getColor('success'),
            text: '#ffffff',
          };
        }

        let highlight = current.noPlateDetected === true || current.filteredBy?.BLACKLIST;

        if (vm.sensorType === 'LineCrossingDetection' && current.direction) {
          highlight = true;
        }

        if (highlight) {
          color = {
            bg: 'red',
            text: '#ffffff',
          };
        }

        let points;
        if (current.points) {
          const _points = angular.copy(current.points);
          applyRatioToPoints(_points);
          points = _points.map((point) => ({
            x: point[0],
            y: point[1],
          }));

          const boxColor = segmentationColors[++currentColor % segmentationColors.length];
          color = {
            bg: boxColor,
            text: '#ffffff',
          };

          let canvasPoints = getCanvasPoints(points);
          let path = canvasPoints.path;

          paper.path(path).attr({
            stroke: boxColor,
            fill: boxColor,
            'stroke-width': vm.stroke,
            'fill-opacity': 0.2,
          });
        } else {
          applyRatioToFrame(frame);
          points = ObjectRecognitionService.getBoxPoints(frame, vm.boxMode);
        }

        let canvasPoints = getCanvasPoints(points);
        let path = canvasPoints.path;

        let box;
        let circle;

        const text = current.text || (current.licensePlate ? current.licensePlate.text : null);

        let label = '';
        if (text) {
          label = `[ ${text} ] `;
        } else if (utils.isValidNumber(current.probability)) {
          const aux = vm.width < 500 ? 10 : 100;
          label += (Math.round(current.probability * aux) / aux).toString();
        }

        if (current.class) {
          const _class = _classesMap[current.class]
            ? _classesMap[current.class].label
            : current.class;

          const classLabel = vm.width < 500 ? _class.substring(0, 3) : _class;
          label = classLabel.toLowerCase() + ': ' + label;

          if (current.noPlateDetected === true) {
            label = $translate.instant('entities.sensor.noPlateDetected');
          }

          circle = paper.circle(applyRatio(centroid.x), applyRatio(centroid.y), vm.circleRadius);
          circle.attr('fill', color.bg);
          circle.attr('stroke-width', 0);
        } else if (current.mask !== undefined) {
          let maskLabel = 'entities.sensor.__faces.';
          maskLabel +=
            current.mask === null || !current.mask.probability ? 'notHasMask' : 'hasMask';

          maskLabel = $translate.instant(maskLabel);
          if (
            current.mask &&
            utils.isValidNumber(current.mask.probability) &&
            current.mask.probability !== 0
          ) {
            label = maskLabel;
            color.bg = ColorUtils.getColor('success');
          } else {
            label = maskLabel;
            color.bg = ColorUtils.getColor('danger');
          }

          color.text = '#ffffff';
        } else if (current.content) {
          label = current.content;
        }

        box = paper.path(path).attr({
          stroke: color.bg,
          fill: 'transparent',
          'stroke-width': vm.stroke,
        });

        const object = paper.set();
        object.push(box);
        object.push(circle);

        label = current.displayName || label;
        if (label && vm.objects.length < 50) {
          const boxLabel = getBoxLabel(paper, canvasPoints.points, label, {
            text: { size: 9, color: color.text },
            line: { color: color.bg },
          });
          object.push(boxLabel.rect);
          object.push(boxLabel.text);
        }

        if (current.landmarks) {
          let marks;

          if (utils.isPlainObject(current.landmarks)) {
            marks = Object.values(current.landmarks);
          } else {
            marks = current.landmarks;
          }
          marks = Array.isArray(marks) ? marks : [];

          marks.forEach((mark, index) => {
            const circle = paper
              .circle(applyRatio(mark.x), applyRatio(mark.y), vm.circleRadius)
              .attr('fill', 'yellow')
              .attr('stroke-width', 0);
            object.push(circle);
          });
        }

        if (current.trace) {
          let trace = [];
          current.trace.forEach((_this, index) => {
            if (!_this.frame) {
              return;
            }
            _this = angular.copy(_this);
            applyRatioToFrame(_this.frame);
            trace.push([index === 0 ? 'M' : 'L', _this.frame.x, _this.frame.y]);
          });
          trace.push(['L', frame.x, frame.y]);
          const tracePath = paper.path(trace).attr({ stroke: 'red' });
          object.push(tracePath);
        }
      });

      const minProb = 0.1;
      const bannedPoints = ['l_eye', 'r_eye', 'l_ear', 'r_ear', 'nose'];
      vm.poses.forEach((pose) => {
        pose = angular.copy(pose);
        const object = paper.set();

        const lowerKeypoints = {};

        const frame = ObjectRecognitionService.getPoseFrame(pose);
        frames.push(frame);
        applyRatioToFrame(frame);
        const points = ObjectRecognitionService.getBoxPoints(frame);

        const isFiltered = Object.values(pose.filteredBy || {}).reduce(
          (filtered, value) => filtered && value,
          true
        );

        if (!isFiltered) {
          pose.hidden = true;
          return;
        }

        for (let key in pose.keypoints) {
          const value = pose.keypoints[key];

          key = key.toLowerCase();

          const probability = (value.probability = parseFloat(value.probability));
          if (probability < minProb || bannedPoints.indexOf(key) !== -1) {
            continue;
          }

          let x = (value.point.x = applyRatio(parseFloat(value.point.x)));
          let y = (value.point.y = applyRatio(parseFloat(value.point.y)));

          const posePoint = _posePointsMap[key] || { color: 'yellow' };

          const circle = paper.circle(x, y, vm.circleRadius).attr({
            fill: posePoint.color,
            'stroke-width': 0,
          });
          circle.toBack();
          object.push(circle);
          if (circle.node) {
            circle.node.setAttribute('pose-point', key);
          }

          lowerKeypoints[key] = value;
        }

        _bodyPairs.forEach((current) => {
          const color = current.color;

          const path = [];
          let index = 0;
          const label = [];
          let probability = 0;
          current.pair.forEach((bodyPair) => {
            const point = lowerKeypoints[bodyPair];

            if (!point || !utils.isValidNumber(point.probability) || point.probability < minProb) {
              return;
            }

            path.push([index === 0 ? 'M' : 'L', point.point.x, point.point.y]);
            index++;
            label.push(bodyPair);

            probability += point.probability;
          });

          if (path.length < 2) {
            return;
          }
          const paperPath = paper.path(path).attr({
            stroke: color,
            'stroke-width': vm.stroke,
            'stroke-opacity': Math.min(1, probability / path.length),
          });

          paperPath.toBack();
          if (paperPath.node) {
            paperPath.node.setAttribute('pose-points', label.join('-'));
          }
        });

        let canvasPoints = getCanvasPoints(points);
        let path = canvasPoints.path;

        let box = paper.path(path).attr({
          stroke: linesColors.default.bg,
          fill: 'transparent',
          'stroke-width': 1,
          'stroke-opacity': 0.8,
        });
        object.push(box);
        let label = pose.label.toLowerCase();
        label = _posesMap[label] ? _posesMap[label].label : label;
        const boxLabel = getBoxLabel(paper, canvasPoints.points, label, {
          text: { size: 9, color: linesColors.default.text },
          line: { color: linesColors.default.bg },
        });

        object.push(boxLabel.rect);
        object.push(boxLabel.text);
      });

      for (const pair of vm.pairs) {
        const frame1 = frames.find((current) => current._index === pair[0]);
        const frame2 = frames.find((current) => current._index === pair[1]);
        if (!frame1 || !frame2) {
          continue;
        }

        paper.path(['M', frame1.x, frame1.y, 'L', frame2.x, frame2.y]).attr({
          stroke: ColorUtils.getColor('danger'),
          'stroke-width': vm.stroke * 2,
        });
      }

      return paper;
    }

    function applyRatioToFrame(frame) {
      frame.x = applyRatio(frame.x);
      frame.y = applyRatio(frame.y);
      frame.w = applyRatio(frame.w);
      frame.h = applyRatio(frame.h);
    }

    function applyRatioToPoints(points) {
      points.forEach((point) => {
        point[0] = applyRatio(point[0]);
        point[1] = applyRatio(point[1]);
      });
    }

    function applyRatio(number) {
      const value = Math.round(number * vm.ratio * 10000) / 10000;
      return value >= 0 ? value : 0;
    }

    function getCanvasPoints(points) {
      const path = [];
      const box = [];

      points.forEach((point, index) => {
        const x = point.x;
        const y = point.y;
        let svgOp = 'L';
        if (index === 0) {
          svgOp = 'M';
        }
        path.push([svgOp, x, y]);
        box.push({ x: x, y: y });
      });

      path.push(['Z']);

      return {
        path: path,
        points: box,
      };
    }

    function getBoxLabel(paper, points, label, options) {
      const lineOps = options.line;
      const textOps = options.text;

      const p1 = points[0];
      const p3 = points[3];

      const textWidth = utils.getTextWidth(label, null, { fontSize: textOps.size + 'px' });
      let pos = { x: p1.x + textWidth / 2, y: p1.y - textOps.size / 2 };
      if (pos.y < 0) {
        pos.y = p3.y + textOps.size / 2;
      }

      const text = paper.text(pos.x, pos.y, label).attr({
        fill: textOps.color,
        'font-size': textOps.size + 'px',
      });
      const bbox = text.getBBox();

      const rect = paper
        .rect(bbox.x, bbox.y - 1, bbox.width + 1, bbox.height + 1)
        .attr('fill', lineOps.color)
        .attr('stroke', lineOps.color);

      text.toFront();

      return {
        rect: rect,
        text: text,
      };
    }
  }
})();
