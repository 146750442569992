(function () {
  'use strict';

  let angular = window.angular;

  Controller.$inject = ['$element', 'DetectionCanvasUtilsService'];

  angular.module('app').component('detectionObjectsCanvas', {
    template:'<div tabindex="0" style="position: relative; margin: auto" class="detections-canvas-container"></div>',
    controller: Controller,
    controllerAs: 'ctrl',
    bindings: {
      sensor: '<',
      data: '<',
      image: '<',
      drawZone: '<?',
      filterByZone: '<?',
      ratio: '<',
      width: '<',
      height: '<',
      copyObjects: '<?',
    },
  });

  function Controller($element, DetectionCanvasUtilsService) {
    let vm = this;
    let firstChange = true;

    vm.$onInit = function () {
      vm.canvasTarget = $element.find('.detections-canvas-container');
      // vm.ratio = utils.isValidNumber(vm.ratio) ? vm.ratio : 1;
      initDrawing();
    };

    vm.$onDestroy = function () {};

    vm.$onChanges = function (changes) {
      if (firstChange) {
        firstChange = false;
        return;
      }
      initDrawing();
    };

    function initDrawing() {
      if (!vm.data || !vm.image) {
        return;
      }

      const canvas = DetectionCanvasUtilsService.drawDetections({
        canvas: vm.canvas,
        image: vm.image,
        width: vm.width,
        height: vm.height,
        ratio: vm.ratio,
        data: vm.data,
        sensor: vm.sensor,
        drawZone: vm.drawZone,
        filterByZone: vm.filterByZone,
        drawLabels: true,
        drawOverImage: true,
        copy: vm.copyObjects,
      });

      if (!vm.canvas) {
        vm.canvas = canvas;
        vm.canvas.style.margin = 'auto';
        vm.canvas.style.display = 'block';
        vm.canvasTarget.append(vm.canvas);
      }
    }
  }
})();
