(function () {
  'use strict';

  let angular = window.angular;

  ContextualizationRouter.$inject = ['$stateProvider'];

  angular.module('app').config(ContextualizationRouter);

  function ContextualizationRouter($stateProvider) {
    $stateProvider
      .state('member.project.assetTemplates', {
        url: '/asset-templates/:assetTemplateId?tab',
        component: 'contextualizationAssetTemplate',
        params: {
          assetTemplateId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
          tab: {
            type: 'string',
            dynamic: true,
          },
        },
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.assettemplate.modelNamePl" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + '/js/vendors/editors/cronstrue-i18n.min.js',
                window.assetsPath + `/css/editors-${style}.css`,
                window.assetsPath + '/js/app/contextualization.module.min.js',
              ]);
            },
          ],
          assetIcons: [
            'iconService',
            function (iconService) {
              return iconService.getAssetIcons();
            },
          ],
          users: [
            'Customer',
            'Manager',
            function (Customer, Manager) {
              let user = Manager.getCachedCurrent();
              return Customer.managers({ id: user.customerId }).$promise;
            },
          ],
          assetTemplates: [
            'Customer',
            'Manager',
            '$transition$',
            'AssetTemplateService',
            function (Customer, Manager, $transition$, assetTemplateService) {
              let params = $transition$.params();
              let user = Manager.getCachedCurrent();
              return Customer.prototype$__get__projects__assetTemplates({
                id: user.customerId,
                nk: params.projectId,
              }).$promise.then((assets) => {
                return assetTemplateService.getAssetTemplateTree(assets);
              });
            },
          ],
        },
      })
      .state('member.project.contextualization', {
        url: '/contextualization',
        template: '<ui-view></ui-view>',
        redirecTo: 'member.project.contextualization.assets({assetId: null, tab: null})',
        params: {},
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.tool.static.contextualization" | translate}}',
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            '$rootScope',
            function ($ocLazyLoad, $root) {
              const style = $root.darkMode ? 'dark' : 'light';
              return $ocLazyLoad.load([
                window.assetsPath + '/js/vendors/gmap-vendors.min.js',
                window.assetsPath + '/js/vendors/pdf-vendors.min.js',
                window.assetsPath + '/js/vendors/editors/simplemde.min.js',
                window.assetsPath + '/js/vendors/editors/jsoneditor.min.js',
                window.assetsPath + '/js/vendors/editors/cronstrue-i18n.min.js',
                window.assetsPath + '/js/vendors/editors/jsondiffpatch.umd.js',
                window.assetsPath + `/css/editors-${style}.css`,
                window.assetsPath + '/js/app/contextualization.module.min.js',
                'https://cdn.jsdelivr.net/gh/davidshimjs/qrcodejs/qrcode.min.js',
              ]);
            },
          ],
        },
      })

      .state('member.project.contextualization.assets', {
        url: '/assets/:assetId?tab&category',
        component: 'contextualizationAsset',
        params: {
          assetId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
          tab: {
            type: 'string',
            dynamic: true,
          },
          category: {
            type: 'string',
            dynamic: true,
          },
        },
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.asset.modelNamePl" | translate}}',
        },
        resolve: {
          assetIcons: [
            'iconService',
            function (iconService) {
              return iconService.getAssetIcons();
            },
          ],
          users: [
            'Customer',
            'Manager',
            function (Customer, Manager) {
              let user = Manager.getCachedCurrent();
              return Customer.managers({ id: user.customerId }).$promise;
            },
          ],
        },
      })
      .state('member.project.contextualization.agents', {
        url: '/agents/:agentId?deviceId',
        component: 'contextualizationAgent',
        params: {
          agentId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
          deviceId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
        },
        authenticated: true,
        ncyBreadcrumb: {
          label: '{{"entities.edgeagent.modelNamePl" | translate}}',
        },
        resolve: {
          assets: [
            'assetUtilsService',
            function (AssetUtils) {
              return AssetUtils.loadTree(
                true,
                ['id', 'name', 'icon', 'assetId', 'projectId'],
                undefined,
                true,
                true
              );
            },
          ],
          devices: [
            'Customer',
            'Manager',
            'AppUtils',
            'assetUtilsService',
            function (Customer, Manager, utils, assetUtilsService) {
              //let params = $transition$.params();
              let user = Manager.getCachedCurrent();
              let devices = [];
              let deviceMap = {};
              return Customer.devices({
                id: user.customerId,
                filter: {
                  fields: ['id', 'name', 'requested'],
                  include: { relation: 'edgeAgents', scope: { fields: ['id', 'name', 'enabled'] } },
                },
              })
                .$promise.then((result) => {
                  devices = result;
                  devices.sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
                  devices.forEach((device) => {
                    device.status = assetUtilsService.getStatus(device);
                    deviceMap[device.id] = device;
                  });

                  let tree = [];
                  devices.forEach((device) => {
                    tree.push({
                      id: device.id,
                      text: device.name,
                      version: device.version,
                      parent: '#',
                      li_attr: {
                        'data-status': device.status === 'offline' ? 'offline' : 'undefined',
                      },
                    });
                    device.edgeAgents.forEach((agent) => {
                      tree.push({
                        id: agent.id,
                        text: agent.name,
                        parent: device.id,
                        li_attr: {
                          'data-status': agent.enabled ? 'online' : 'undefined',
                        },
                      });
                    });
                  });
                  return { tree: tree, devices: devices, hola: 'hola' };
                })
                .catch((err) => {
                  throw utils.getHTTPError(err, {
                    redirectTo: 'member.home',
                    params: { customerId: user.customerId },
                  });
                });
            },
          ],
        },
      })

      // Virtual Expressions
      .state('member.project.kpiEngine', {
        url: '/kpi-engine/:virtualGroupId?tab',
        component: 'kEngineHome',
        authenticated: true,
        params: {
          virtualGroupId: {
            type: 'string',
            dynamic: true,
            squash: true,
          },
          tab: {
            type: 'string',
            dynamic: true,
          },
        },
        resolve: {
          dependencies: [
            '$ocLazyLoad',
            function ($ocLazyLoad) {
              return $ocLazyLoad.load([
                'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.3/MathJax.js?config=TeX-AMS-MML_HTMLorMML.js',
                window.assetsPath + '/js/app/contextualization.module.min.js',
              ]);
            },
          ],
          virtualGroups: [
            'Manager',
            '$transition$',
            'VirtualGroupService',
            function (Manager, $transition$, VirtualGroupService) {
              let params = $transition$.params();
              let user = Manager.getCachedCurrent();
              return VirtualGroupService.find(user.customerId, params.projectId).then((groups) => {
                return { tree: VirtualGroupService.getTree(groups), virtualGroups: groups };
              });
            },
          ],
        },
        ncyBreadcrumb: {
          label: '{{"entities.tool.static.kpiEngine" | translate}}',
        },
      });
  }
})();
